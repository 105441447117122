import React from 'react';
import config from './config.json';
import JSONInput from "react-json-editor-ajrm/index";
var CryptoJS = require("crypto-js");
class App extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          obj: config,
      };
      this.objUpdate = this.objUpdate.bind(this);

  }
componentDidMount() {

}

    objUpdate(arg){
        if(arg.jsObject){
            console.log(arg.jsObject)
            this.setState({obj: arg.jsObject});
        }
    }
  genCryptoText=()=>{
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(this.state.obj), 'bsYn!123#00').toString();
    const element = document.createElement("a");
      const json = JSON.stringify({bsyn:ciphertext});
    var file = new Blob([json], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = "config.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

  }

  render() {

    return (
        <div id="root" className="wrapper">
            <div className="top-sticky-bar">
                <div className="flex-between">
                    <div className="brand-sub-logo">
                        <img src="https://d22znjc9y9upyw.cloudfront.net/assets/bsynapse/images/brand-logo-black.png"/>
                    </div>
                    <div className="user-othe-info">
                        <button className="general-btn"  onClick={()=>this.genCryptoText()}>Generate</button>
                    </div>
                </div>
            </div>
            <div className="content-body">
              <JSONInput
                  placeholder={config} // data to display
                  theme="darktheme"
                  width={"100%"}
                  height="100%"
                  onChange    = { this.objUpdate }
              />
                <div>
              </div>
            </div>
        </div>


                )


  }
}

export default App;
